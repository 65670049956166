import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import WebPricing from '../components/PricingPlans/WebPricing';
import DOMPurify from 'dompurify';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import MinecraftDetailsImage from "../assets/images/jeux/minecraft-details.webp";
import MinecraftDetailsImage2 from "../assets/images/jeux/minecraft-details2.jpg";

const serviceImgStyle = {
    maxHeight:'500px',
    maxWidth:'600px',
    borderRadius: '10%',
 }

 const rawHTML = 
 `
 <!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 16px;
}

th:first-child, td:first-child {
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2
}

.fa-check {
  color: green;
}

.fa-remove {
  color: red;
}
</style>
</head>
<body>

<h3>Comparaison de notre offre Pro avec les offres équivalentes d'autres marques</h3>

<table>
  <tr>
    <th style="width:50%">Fonctionnalités</th>
    <th>Fenua Hosting</th>
    <th>Tahiti Hoster</th>
    <th>Vini (Mana)</th>
  </tr>
  <tr>
    <td>Prix</td>
    <td>3000F</td>
    <td>3000F</td>
    <td>3390F</td>
  </tr>
  <tr>
    <td>Espace disque</td>
    <td>1.5Go</td>
    <td>3Go</td>
    <td>500Mo</td>
  </tr>
  <tr>
    <td>Sous-domaine</td>
    <td>Illimité</td>
    <td>1</td>
    <td>1</td>
  </tr>
  <tr>
    <td>Base de données</td>
    <td>2</td>
    <td>1</td>
    <td>1</td>
  </tr>
  <tr>
    <td>SSL gratuit</td>
    <td><i class="fa fa-check"></i></td>
    <td><i class="fa fa-check"></i></td>
    <td>N/A</td>
  </tr>
  <tr>
    <td>accès FTP</td>
    <td><i class="fa fa-check"></i></td>
    <td><i class="fa fa-check"></i></td>
    <td><i class="fa fa-check"></i></td>
  </tr>
  <tr>
    <td>Conforme RGPD</td>
    <td><i class="fa fa-check"></td>
    <td><i class="fa fa-remove"></td>
    <td><i class="fa fa-check"></td>
  </tr>
  <tr>
    <td>accès SSH</td>
    <td><i class="fa fa-check"></i></td>
    <td><i class="fa fa-remove"></i></td>
    <td><i class="fa fa-remove"></i></td>
  </tr>
  <tr>
    <td>Configuration instantanée</td>
    <td><i class="fa fa-check"></i></td>
    <td><i class="fa fa-remove"></i></td>
    <td><i class="fa fa-remove"></i></td>
  </tr>
  <tr>
    <td>Panel</td>
    <td>FenuaHosting</td>
    <td>CPanel</td>
    <td><i class="fa fa-remove"></i></td>
  </tr>
  <tr>
    <td>Email</td>
    <td>5 comptes</td>
    <td>3 comptes</td>
    <td>200 comptes?</td>
  </tr>
  <tr>
    <td>Sauvegarde</td>
    <td>Chaque nuit</td>
    <td>journalières</td>
    <td>N/A</td>
  </tr>
  <tr>
    <td>localisation physique serveur</td>
    <td>Tahiti</td>
    <td>USA</td>
    <td>Tahiti</td>
  </tr>
  <tr>
    <td>Temps d'attente en seconde*</td>
    <td id="performance-display">Temps...</td>
    <td>4.30</td>
    <td>2.95</td>
  </tr>
</table>
<p>* Le temps d'attente pour le chargement de la page complète du site vitrine de chaque marque.
  <br /> &nbsp; Ici, le temps de FenuaHosting est calculé en temps réel depuis votre connexion! <i class="fa fa-rocket" aria-hidden="true"></i>
</p>

</body>
</html>

 `;

const ServiceDetails = () => (
    <Layout>
        <SEO title="Hébergement Web" /> 

        <Navbar />

        <PageBanner pageTitle="Hébergement Web &amp; Email | Tahiti" />

        <h1 style={{textAlign: "center"}}>Bientôt disponible</h1>
       {/* <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                
                            <h3>Lance ton site internet en 1 clique !</h3>
                            <p>En Polynésie française, il n’est pas simple de trouver une solution d’hébergement rapide à cause de la localisation physique de notre territoire. Fenua Hosting vous propose une offre clés en main d’hébergement optimisé et pensé pour Tahiti & ses îles. Du choix du datacenter à l’optimisation du temps de réponse de nos serveurs, nous avons contrôlé chaque segment pour vous garantir une performance ultime !</p>
                            <ul>
                                <li>SSD NVME de type entreprise.</li>
                                <li>Configuration custom d’Apache et serveur de caching.</li>
                                <li>Un environement sécurisé avec cloisonnement et ressources dédiées.</li>
                                <li>Sauvegarde gratuite & automatique chaque nuit.</li>
                            </ul>
                
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                { <div dangerouslySetInnerHTML={{ __html: rawHTML }} /> }
                
                </div>
            </div>
        </div>

<WebPricing />*/}

        <Footer />
    </Layout>
)

export default ServiceDetails;

const delay = ms => new Promise(res => setTimeout(res, ms));

const jsCode = `
var loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
console.log('Page load time is ' + loadTime / 1000);
performanceDisplay = document.getElementById("performance-display"); // get a reference to the paragraph
if (performanceDisplay != null) {
    performanceDisplay.innerText = loadTime / 1000; // put the value of the variable loadTime into the paragraph
}
`;
const yourFunction = async () => {
    await delay(1500);
    new Function(jsCode)();
  };
yourFunction();


