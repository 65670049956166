import React, { Component } from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import AgencyShape1 from "../../assets/images/agency-image/agency-shape1.png";

class WebPricing extends Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render() {
        return (
            <div className="pricing-area pb-50">
                <div className="container">
                    <div className="section-title st-fs-28">
                        <span className="sub-title">Tarifs</span>
                        <h2>Tarifs</h2>
                        <div className="bar"></div>
                        <p>Retrouvez ci-dessous nos différents abonnements.</p>
                    </div>
                    
                    <div className="tab pricing-tab bg-color">
                        <ul className="tabs" style={{display: 'none'}}>
                            <li
                                className="current"
                                onClick={(e) => this.openTabSection(e, 'tab1')}
                            >
                                Mensuel
                            </li>
                            
                            <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                                Annuel <i data-feather="calendar"></i>
                            </li>
                        </ul>

                        <div className="tab_content">
                            <div id="tab1" className="tabs_item">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Gratuit*</h3>
                                                <p>Conseillé pour <br /> un petit blog</p>
                                            </div>

                                            <div className="price">
                                                0 XPF <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Lancez vous!
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check />1 Wordpress</li>
                                                <li><Icon.Check />512Mo disque NVME</li>
                                                <li><Icon.Check />Accès au Panel</li>
                                                <li><Icon.Slash color="red"/>Pas d'accès FTP/SSH</li>
                                                <li><Icon.Slash color="red"/>Pas de sous domaine</li>
                                                <li><Icon.Slash color="red"/>Pas de compte email</li>
                                                <li><Icon.Slash color="red"/>CPU limité</li>
                                                <li><Icon.Check />SSL gratuit</li>
                                                <li><Icon.Check />Sauvegarde auto</li>
                                                <li><Icon.Check />Support par email/ticket</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Basic</h3>
                                                <p>Conseillé pour <br /> un site personnel</p>
                                            </div>

                                            <div className="price">
                                                1250 XPF <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Lancez votre site!
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check />1 site</li>
                                                <li><Icon.Check />1Go disque NVME</li>
                                                <li><Icon.Check />768Mo RAM dédiée</li>
                                                <li><Icon.Check />Accès au Panel</li>
                                                <li><Icon.Slash color="red"/>Pas d'accès FTP/SSH</li>
                                                <li><Icon.Slash color="red"/>Pas de sous domaine</li>
                                                <li><Icon.Slash color="red"/>Pas de compte email</li>
                                                <li><Icon.Check />SSL gratuit</li>
                                                <li><Icon.Check />Sauvegarde auto</li>
                                                <li><Icon.Check />Support par email/ticket</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Conseillé pour <br /> un site pro</p>
                                            </div>

                                            <div className="price">
                                                3000 XPF <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Lancez votre site Pro!
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check />2 site</li>
                                                <li><Icon.Check />2Go disque NVME</li>
                                                <li><Icon.Check />1Go RAM dédiée</li>
                                                <li><Icon.Check />Accès au Panel</li>
                                                <li><Icon.Check />Accès FTP/SSH</li>
                                                <li><Icon.Check />Sous domaine illimités</li>
                                                <li><Icon.Check />5 comptes email</li>
                                                <li><Icon.Check />SSL gratuit</li>
                                                <li><Icon.Check />Sauvegarde auto</li>
                                                <li><Icon.Check />Support par email/ticket</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Premium</h3>
                                                <p>Conseillé pour <br /> une entreprise</p>
                                            </div>

                                            <div className="price">
                                                8595 XPF <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Passez Premium!
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check />Sites illimités</li>
                                                <li><Icon.Check />6Go disque NVME</li>
                                                <li><Icon.Check />2Go RAM dédiée</li>
                                                <li><Icon.Check />Accès au Panel</li>
                                                <li><Icon.Check />Accès FTP/SSH</li>
                                                <li><Icon.Check />Sous domaine illimités</li>
                                                <li><Icon.Check />5 comptes email</li>
                                                <li><Icon.Check />SSL gratuit</li>
                                                <li><Icon.Check />Sauvegarde auto</li>
                                                <li><Icon.Check />Support par email/ticket</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <p>*Gratuit : Dans la limite des stocks disponibles. Uniquement pour les élèves et étudiants, une preuve de scolarité est exigée mais n'est pas conservée.
                                Pour souscrire, envoyez les documents à contact@fenuahosting.com et c'est parti !</p>
                            </div>

                            <div id="tab2" className="tabs_item" style={{display: "none"}}>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Free</h3>
                                                <p>Get your business up <br /> and running</p>
                                            </div>

                                            <div className="price">
                                                $0 <span>/y</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Get Started Free
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Drag & Drop Builder</li>
                                                <li><Icon.Check /> Lead Generation & Sales</li>
                                                <li><Icon.Check /> Boot & Digital Assistants</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 1000 Subscribers</li>
                                                <li><Icon.Check /> Unlimited Broadcasts</li>
                                                <li><Icon.Check /> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Get your business up <br /> and running</p>
                                            </div>

                                            <div className="price">
                                                $249 <span>/y</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Start 3 Days Free Trial
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Drag & Drop Builder</li>
                                                <li><Icon.Check /> Lead Generation & Sales</li>
                                                <li><Icon.Check /> Boot & Digital Assistants</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 3300 Subscribers</li>
                                                <li><Icon.Check /> Unlimited Broadcasts</li>
                                                <li><Icon.Check /> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Premium</h3>
                                                <p>Get your business up <br /> and running</p>
                                            </div>

                                            <div className="price">
                                                $279 <span>/y</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/login" className="btn btn-primary">
                                                    Start 6 Days Free Trial
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Drag & Drop Builder</li>
                                                <li><Icon.Check /> Lead Generation & Sales</li>
                                                <li><Icon.Check /> Boot & Digital Assistants</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 10000 Subscribers</li>
                                                <li><Icon.Check /> Unlimited Broadcasts</li>
                                                <li><Icon.Check /> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shape Image */}
                <div className="shape9 mobile-dnone">
                    <img src={AgencyShape1} alt="image" />
                </div>
            </div>
        );
    }
}

export default WebPricing;